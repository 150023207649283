@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellefair&family=David+Libre:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@100;200;300;400;500;600;700&display=swap');

// font-family: 'Bellefair', serif;
//font-family: 'David Libre', serif;

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Gilroy-R";

  font-display: swap;

  src: url("../fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../fonts/Gilroy-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Gilroy-B";

  font-display: swap;

  src: url("../fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../fonts/Gilroy-Bold.woff") format("woff");
}
