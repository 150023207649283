@import './fonts';
@import './reset';
@import './checkbox';


body {
    font-family: "IBM Plex Sans Hebrew",serif;
}

*{
    box-sizing: border-box;
}
